/****************************
****  Background images  ****
****************************/

.background-image {
    background-position: center center;
    background-repeat: no-repeat;
    // background-size: cover;
}


.background-image.b-p-left-top{
    background-position: left top;
}

.background-image.b-p-left-center{
    background-position: left center;
}

.background-image.b-p-left-bottom{
    background-position: left bottom;
}

.background-image.b-p-center-top{
    background-position: center top;
}

.background-image.b-p-center-center{
    background-position: center center;
}

.background-image.b-p-center-bottom{
    background-position: center bottom;
}

.background-image.b-p-right-top{
    background-position: right top;
}

.background-image.b-p-right-center{
    background-position: right center;
}

.background-image.b-p-right-bottom{
    background-position: right bottom;
}


.background-image
{
    background-repeat: no-repeat;

    &.contain{
        background-size: contain;
    }

    &.cover{
        background-size: cover;
    }

    &.fixed{
        background-attachment: fixed;
        @include breakpoint(medium down){
            // Fixed does not work on ios devices!
            background-attachment:scroll;
        }
    }

    @include breakpoint(medium down){
        min-height:200px;
    }

}


.background-image
{

    &.overlay::before
    {
        content: '';
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        z-index:1;
                
        background-color: rgba($white,.3);
    }

    &.multiply-lightgray::before
    {
        background-color: rgba($dark-gray,.65);//$secondary-color;
        mix-blend-mode: multiply;
    }

    &.multiply-black::before
    {
        background-color: rgba($black,.3);
        mix-blend-mode: multiply;
    }
}

@include breakpoint(xlarge){
    .background-image.cover{
        margin: 30px;
    }
}

.background-image.cover .grid-container{
    z-index: 2;
}