.image-cell figcaption{
    position: absolute;
    bottom: 6px;
    background-color: rgba($white, .9);
    padding: rem-calc(5px 10px);
}

.image-cell figure{
    position: relative;
    a{
        display: block;
        position: relative;
        figcaption{
            color: $black;
        }
    }
}



/***********************************/
/********** RISE OR LOWER **********/
/***********************************/

[class*="rise-"], 
[data-defo-rise]{
	align-self:start;
}

@include breakpoint(medium){
    .medium-rise-50{
        margin-top: -50px;
    }
    .medium-rise-100{
        margin-top: -100px;
    }
    .medium-rise-150{
        margin-top: -150px;
    }
    .medium-rise-200{
        margin-top: -200px;
    }
    .medium-rise-250{
        margin-top: -250px;
    }
    .medium-rise-300{
        margin-top: -300px;
    }
    .medium-rise-350{
        margin-top: -350px;
    }
    .medium-rise-400{
        margin-top: -400px;
    }
    .medium-rise-450{
        margin-top: -450px;
    }
    .medium-rise-500{
        margin-top: -500px;
    }
    .medium-rise-550{
        margin-top: -550px;
    }
    .medium-rise-600{
        margin-top: -600px;
    }
    .medium-rise-650{
        margin-top: -650px;
    }
    .medium-rise-700{
        margin-top: -700px;
    }	


    .medium-lower-50{
        margin-bottom: -50px;
    }
    .medium-lower-100{
        margin-bottom: -100px;
    }
    .medium-lower-150{
        margin-bottom: -150px;
    }
    .medium-lower-200{
        margin-bottom: -200px;
    }
    .medium-lower-250{
        margin-bottom: -250px;
    }
    .medium-lower-350{
        margin-bottom: -350px;
    }
    .medium-lower-400{
        margin-bottom: -400px;
    }
    .medium-lower-450{
        margin-bottom: -450px;
    }
    .medium-lower-500{
        margin-bottom: -500px;
    }
	.medium-lower-550{
        margin-bottom: -550px;
    }
	.medium-lower-600{
        margin-bottom: -600px;
    }
	.medium-lower-650{
        margin-bottom: -650px;
    }
	.medium-lower-700{
        margin-bottom:-700px;
    }
}

@include breakpoint(large){
    .large-rise-50{
        margin-top: -50px;
    }
    .large-rise-100{
        margin-top: -100px;
    }
    .large-rise-150{
        margin-top: -150px;
    }
    .large-rise-200{
        margin-top: -200px;
    }
    .large-rise-250{
        margin-top: -250px;
    }
    .large-rise-300{
        margin-top: -300px;
    }
    .large-rise-350{
        margin-top: -350px;
    }
    .large-rise-400{
        margin-top: -400px;
    }
    .large-rise-450{
        margin-top: -450px;
    }
    .large-rise-500{
        margin-top: -500px;
    }
    .large-rise-550{
        margin-top: -550px;
    }
    .large-rise-600{
        margin-top: -600px;
    }
    .large-rise-650{
        margin-top: -650px;
    }
    .large-rise-700{
        margin-top: -700px;
    }	


    .large-lower-50{
        margin-bottom: -50px;
    }
    .large-lower-100{
        margin-bottom: -100px;
    }
    .large-lower-150{
        margin-bottom: -150px;
    }
    .large-lower-200{
        margin-bottom: -200px;
    }
    .large-lower-250{
        margin-bottom: -250px;
    }
    .large-lower-350{
        margin-bottom: -350px;
    }
    .large-lower-400{
        margin-bottom: -400px;
    }
    .large-lower-450{
        margin-bottom: -450px;
    }
    .large-lower-500{
        margin-bottom: -500px;
    }
	.large-lower-550{
        margin-bottom: -550px;
    }
	.large-lower-600{
        margin-bottom: -600px;
    }
	.large-lower-650{
        margin-bottom: -650px;
    }
	.large-lower-700{
        margin-bottom:-700px;
    }
}

@include breakpoint(xlarge){
    .xlarge-rise-50{
        margin-top: -50px;
    }
    .xlarge-rise-100{
        margin-top: -100px;
    }
    .xlarge-rise-150{
        margin-top: -150px;
    }
    .xlarge-rise-200{
        margin-top: -200px;
    }
    .xlarge-rise-250{
        margin-top: -250px;
    }
    .xlarge-rise-300{
        margin-top: -300px;
    }
    .xlarge-rise-350{
        margin-top: -350px;
    }
    .xlarge-rise-400{
        margin-top: -400px;
    }
    .xlarge-rise-450{
        margin-top: -450px;
    }
    .xlarge-rise-500{
        margin-top: -500px;
    }
    .xlarge-rise-550{
        margin-top: -550px;
    }
    .xlarge-rise-600{
        margin-top: -600px;
    }
    .xlarge-rise-650{
        margin-top: -650px;
    }
    .xlarge-rise-700{
        margin-top: -700px;
    }	


    .xlarge-lower-50{
        margin-bottom: -50px;
    }
    .xlarge-lower-100{
        margin-bottom: -100px;
    }
    .xlarge-lower-150{
        margin-bottom: -150px;
    }
    .xlarge-lower-200{
        margin-bottom: -200px;
    }
    .xlarge-lower-250{
        margin-bottom: -250px;
    }
    .xlarge-lower-350{
        margin-bottom: -350px;
    }
    .xlarge-lower-400{
        margin-bottom: -400px;
    }
    .xlarge-lower-450{
        margin-bottom: -450px;
    }
    .xlarge-lower-500{
        margin-bottom: -500px;
    }
	.xlarge-lower-550{
        margin-bottom: -550px;
    }
	.xlarge-lower-600{
        margin-bottom: -600px;
    }
	.xlarge-lower-650{
        margin-bottom: -650px;
    }
	.xlarge-lower-700{
        margin-bottom:-700px;
    }
}