/****************************
****  Background videos  ****
****************************/

// ONLY MP4 files for background videos (YT and Vimeo can't scale properly)
.background-video {
    position: relative;
    overflow: hidden;
}
.video-container {
    background: #000;
    position: absolute;
    top: 0; 
    right: 0; 
    bottom: 0; 
    left: 0;
    z-index: -99;
    // position: relative;
    // overflow: hidden;
    // width: calc(100vw - 15px);
    // height: 100vh;
    // transform: translateX(-50%);
    // left: 50%;
}

// .video-container {
//     position: relative;
//     overflow: hidden;
//     width: 100vw;
//     height: 100vh;
// }

.video-container iframe {
    // position: absolute;
    // top: 50%;
    // left: 50%;
    // width: 100vw;
    // height: 100vh;
    // transform: translate(-50%, -50%);
    position: absolute;
    top: 0; 
    left: 0;
    width: 100%; 
    height: 100%;
    //object-fit: cover;
    pointer-events: none;
}

// @media (min-aspect-ratio: 16/9) {
//     .video-container iframe {
//         /* height = 100 * (9 / 16) = 56.25 */
//         height: 56.25vw;
//     }
// }
// @media (max-aspect-ratio: 16/9) {
//     .video-container iframe {
//         /* width = 100 / (9 / 16) = 177.777777 */
//         width: 177.78vh;
//     }
// }


.background-video .grid-container .grid-margin-x.align-center.align-middle{
    padding-top: 0;
    padding-bottom: 0;
    .cell{
        margin-bottom: 0;
    }
}

// .background-video .video-container.overlay::before{
//     content: '';
//     display: block;
//     height: 100%;
//     width: calc(100% + 10px);
//     background-color: rgba(255, 255, 255, 0.5);
//     left: -5px;
//     position: absolute;
//     z-index: 1;
//     top: 0;
// }


.background-video
{

    .overlay::before
    {
        content: '';
        display: block;
        position: absolute;
        width: calc(100% + 5px);
        height: 100%;
        left: 0;
        top: 0;
        z-index:1;
                
        background-color: rgba($white,.3);
    }

    .multiply-lightgray::before
    {
        background-color: rgba($dark-gray,.65);//$secondary-color;
        mix-blend-mode: multiply;
    }

    .multiply-black::before
    {
        background-color: rgba($black,.3);
        mix-blend-mode: multiply;
    }
}

@include breakpoint(large){
    .background-video.cover{
        margin: 30px;
    }
}