/*----------------------------------------------

B A C K G R O U N D S

------------------------------------------------*/

.primary-background
{
    background-color: $primary-color;
    color: $white;


    // .cell,
    // &.cell{
    //.cell{
    .cell:not(.white-background){
        .paragraph-container{
            color: $white;
        }
    }

    .cell.white-background{
        .paragraph-container{
            color: inherit;
        }
    }
}

.cell.with-background
{
    padding:rem-calc(map-get($grid-margin-gutters , medium));
    padding-bottom: calc(#{rem-calc(map-get($grid-margin-gutters, medium))} - #{$paragraph-margin-bottom});

    @include breakpoint(medium){
        padding:rem-calc(map-get($grid-margin-gutters , large));
        padding-bottom: calc(#{rem-calc(map-get($grid-margin-gutters, large))} - #{$paragraph-margin-bottom});
    }
}

// .with-background.primary-background 
// {
//     .paragraph-container{
//         color: $white;
//     }
// }
// .primary-background
// {
//     background-color: $primary-color;

//     &:not(body),
//     &:not(.background-image)
//     {
//         .cell,
//         &.cell{
//         // &:not(body) .cell{
//             //p, a, li, h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6, td, label{
//             p, li, h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6, td, label{
//                 &:not(.secondary-color){
//                     color: $white;
//                 }
//             }

//             // p a{
//             //     border-bottom-color: $white;
//             // }
//         }
//     }

// }


.secondary-background
{
    background-color: $secondary-color;

    // &.transparent{
    //     background-color: rgba($secondary-color, 0.85);
    // }

    // &.transparency-100{  background-color: rgba($secondary-color, 1);     }

    // &.transparency-90{   background-color: rgba($secondary-color, 0.90);  }

    // &.transparency-80{   background-color: rgba($secondary-color, 0.80);  }

    // &.transparency-70{   background-color: rgba($secondary-color, 0.70);  }

    // &.transparency-60{   background-color: rgba($secondary-color, 0.60);  }

    // &.transparency-50{   background-color: rgba($secondary-color, 0.50);  }

    // &.transparency-40{   background-color: rgba($secondary-color, 0.40);  }

    // &.transparency-30{   background-color: rgba($secondary-color, 0.30);  }

    // &.transparency-20{   background-color: rgba($secondary-color, 0.20);  }

    // &.transparency-10{   background-color: rgba($secondary-color, 0.10);  }
}



// .tertiary-background
// {
//     background-color: $tertiary-color;

//     // &.transparent{
//     //     background-color: rgba($tertiary-color, 0.85);
//     // }

//     // &.transparency-100{  background-color: rgba($tertiary-color, 1);     }

//     // &.transparency-90{   background-color: rgba($tertiary-color, 0.90);  }

//     // &.transparency-80{   background-color: rgba($tertiary-color, 0.80);  }

//     // &.transparency-70{   background-color: rgba($tertiary-color, 0.70);  }

//     // &.transparency-60{   background-color: rgba($tertiary-color, 0.60);  }

//     // &.transparency-50{   background-color: rgba($tertiary-color, 0.50);  }

//     // &.transparency-40{   background-color: rgba($tertiary-color, 0.40);  }

//     // &.transparency-30{   background-color: rgba($tertiary-color, 0.30);  }

//     // &.transparency-20{   background-color: rgba($tertiary-color, 0.20);  }

//     // &.transparency-10{   background-color: rgba($tertiary-color, 0.10);  }

// // .cell:not(.textbg){
//     &:not(body),
//     &:not(.background-image)
//     {
//         .cell,
//         &.cell{
//         // &:not(body) .cell{
//             p, a:not(.button), li, h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6, td, label{
//                 color: $white;
//             }

//             // p a{
//             //     border-bottom-color: $white;
//             // }
//         }
//     }
// }



.lightgray-background
{
    background-color: $light-gray;

    // &.transparent{
    //     background-color: rgba($light-gray, 0.85);
    // }

    // &.transparency-100{  background-color: rgba($light-gray, 1);     }

    // &.transparency-90{   background-color: rgba($light-gray, 0.90);  }

    // &.transparency-80{   background-color: rgba($light-gray, 0.80);  }

    // &.transparency-70{   background-color: rgba($light-gray, 0.70);  }

    // &.transparency-60{   background-color: rgba($light-gray, 0.60);  }

    // &.transparency-50{   background-color: rgba($light-gray, 0.50);  }

    // &.transparency-40{   background-color: rgba($light-gray, 0.40);  }

    // &.transparency-30{   background-color: rgba($light-gray, 0.30);  }

    // &.transparency-20{   background-color: rgba($light-gray, 0.20);  }

    // &.transparency-10{   background-color: rgba($light-gray, 0.10);  }
}


.black-background
{
    background-color: $black;

    &:not(body){
        .cell,
        &.cell{
            p, a, li, h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6, td, label{
                color: $white;
            }

            // p a{
            //     border-bottom-color: $medium-gray;
            // }
        }
    }
    // &.transparent{
    //     background-color: rgba($light-gray, 0.85);
    // }

    // &.transparency-100{  background-color: rgba($light-gray, 1);     }

    // &.transparency-90{   background-color: rgba($light-gray, 0.90);  }

    // &.transparency-80{   background-color: rgba($light-gray, 0.80);  }

    // &.transparency-70{   background-color: rgba($light-gray, 0.70);  }

    // &.transparency-60{   background-color: rgba($light-gray, 0.60);  }

    // &.transparency-50{   background-color: rgba($light-gray, 0.50);  }

    // &.transparency-40{   background-color: rgba($light-gray, 0.40);  }

    // &.transparency-30{   background-color: rgba($light-gray, 0.30);  }

    // &.transparency-20{   background-color: rgba($light-gray, 0.20);  }

    // &.transparency-10{   background-color: rgba($light-gray, 0.10);  }
}



// .darkgray-background
// {
//     background-color: $dark-gray;

//     // &.transparent{
//     //     background-color: rgba($dark-gray, 0.85);
//     // }

//     &.transparency-100{  background-color: rgba($dark-gray, 1);     }

//     &.transparency-90{   background-color: rgba($dark-gray, 0.90);  }

//     &.transparency-80{   background-color: rgba($dark-gray, 0.80);  }

//     &.transparency-70{   background-color: rgba($dark-gray, 0.70);  }

//     &.transparency-60{   background-color: rgba($dark-gray, 0.60);  }

//     &.transparency-50{   background-color: rgba($dark-gray, 0.50);  }

//     &.transparency-40{   background-color: rgba($dark-gray, 0.40);  }

//     &.transparency-30{   background-color: rgba($dark-gray, 0.30);  }

//     &.transparency-20{   background-color: rgba($dark-gray, 0.20);  }

//     &.transparency-10{   background-color: rgba($dark-gray, 0.10);  }

//     //.cell:not(.textbg){
//     &:not(body){
//         .cell,
//         &.cell{
//             p, a, li, h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6, td, label{
//                 color: $white;
//             }

//             p a{
//                 border-bottom-color: $medium-gray;
//             }
//         }
//     }
// }



// .primary-background,
// .darkgray-background
// {
//     #page-header,
//     #breadcrumb-line{
//         color: $white;
//         ul li,
//         ul li a{
//             color: $white;
//         }
//     }
// }



.white-background
{
    background-color: $white;

    // &.transparent{
    //     background-color: rgba($white, 0.85);
    // }

    // &.transparency-100{  background-color: rgba($white, 1);     }

    // &.transparency-90{   background-color: rgba($white, 0.90);  }

    // &.transparency-80{   background-color: rgba($white, 0.80);  }

    // &.transparency-70{   background-color: rgba($white, 0.70);  }

    // &.transparency-60{   background-color: rgba($white, 0.60);  }

    // &.transparency-50{   background-color: rgba($white, 0.50);  }

    // &.transparency-40{   background-color: rgba($white, 0.40);  }

    // &.transparency-30{   background-color: rgba($white, 0.30);  }

    // &.transparency-20{   background-color: rgba($white, 0.20);  }

    // &.transparency-10{   background-color: rgba($white, 0.10);  }
}