a {
	text-decoration: none;
    color: $primary-color;
	cursor: pointer;
    transition: $ef-global-transition;
	// &:hover, &:focus{
	// 	color: #dbdbdb;
	// }

	img{
		border: 0;
	}
}


.paragraph-container{
    p, h1, h2, h3, h4, h5, h6, div.button-container
    {

        margin-bottom: rem-calc(20px);
        @include breakpoint(xxlarge){
            margin-bottom: get-vw(20px, 1800);
        }

        &:last-child{
            margin-bottom: rem-calc(80px);
            @include breakpoint(xxlarge){
                margin-bottom: get-vw(80px, 1800);
            }
        }
    }
}

.paragraph-container{
    p, h1, h2, h3, h4, h5, h6
    {
        a{
            position: relative;
            display: inline-block;
            &:after {
                transition: $ef-global-transition;
                display: block;
                content: '';
                position: absolute;
                bottom: 0;
                height: 3px;
                width: 100%;
                background-color: rgba($black, .5);
                transform: translateX(-50%);
                left: 50%;
            }
            &:hover:after{
                // width: 105%;
                background-color: $black;
            }
        }
    }
}

// .paragraph-container{
//     p, h1, h2, h3, h4, h5, h6
//     {
//     }
//     p{ 
//         & + h1, 
//         & + h2, 
//         & + h3, 
//         & + h4, 
//         & + h5, 
//         & + h6{
//             margin-top: 20px;
//         }
//     }
// }

// .paragraph-container{
//     p, h1, h2, h3, h4, h5, h6
//     {
//         a{
//             position: relative;
//             display: inline-block;
//             &:after {
//                 transition: $ef-global-transition;
//                 display: inline-flex;
//                 content: '';
//                 position: absolute;
//                 bottom: 0;
//                 width: 100%;
//                 background-color: $primary-color;
//                 left: 50%;
//                 transform: translateX(-50%);
//                 color: rgba($white, 0%);
//                 font-weight: 600;
//                 align-items: center;
//                 justify-content: left;
//             }
//             &:hover:after{
//                 height: 100%;
//                 width: 110%;
//                 bottom: 0;
//                 background-color: rgba($dark-gray, .8);
//                 content: '\2B62'; //Se mere 
//                 color: rgba($white, 100%);
//                 text-align: center;
//                 line-height: 0;
//                 justify-content: center;
//             }
//             // border-bottom: 8px solid $primary-color;
//             // &:hover{
//             //     border-bottom-color: $dark-gray;
//             // }
//         }
//     }

//     p a:after{
//         height: 3px;
//         font-size: 2em;
//     }
    
//     h1, h2, h3, h4, h5, h6,
//     .h1, .h2, .h3, .h4, .h5, .h6{
//         a:after{
//             height: 8px;
//             font-size: .5em;
//         }
//     }

//     p{
//         &+h1{
//             margin-top: 50px;
//         }
//     }
// }

section, 
.cell{
    &.primary-background .paragraph-container{
        p,
        h1, h2, h3, h4, h5, h6,
        .h1, .h2, .h3, .h4, .h5, .h6{
            a{
                color: $white;
                &:after{
                    background-color: $white;
                }
                &:hover:after{
                    background-color: rgba($dark-gray, .8);
                }
            }
        }
    }
}

.text-size{

    &--header, 
    &--35,
    &--40,
    &--60,
    &--80,{
        font-family: $header-font-family;
        font-style: $header-font-style;
        font-weight: $header-font-weight;
    }

    &--header{
        // margin-bottom: $header-margin-bottom;

        &:first-child{
            margin-top:-10px;
        }   
       
        // //font-size: rem-calc(map-deep-get($header-styles, small, h1, font-size));
        // font-size: rem-calc(40px);
        // line-height: $header-lineheight;
    
        // @include breakpoint(xxlarge){
        //     font-size: get-vw(40px, 1800);
        //     //font-size: rem-calc(map-deep-get($header-styles, medium, h1, font-size));
        // }
        
        // // @include breakpoint(large){
        // //     font-size: rem-calc(map-deep-get($header-styles, large, h1, font-size));
        // // }
    }
    &--27{
        font-weight: 600; //semi-bold

        font-size: rem-calc(18px);
        line-height: rem-calc(23px);
        letter-spacing: -.54px;

        @include breakpoint(large){
            font-size: get-vw(18px, 1024);
            line-height: get-vw(23px, 1024);
        }

        // font-size: rem-calc(27px);
        // letter-spacing: -.54px;
        // line-height: rem-calc(37px);

        // @include breakpoint(xxlarge){
        //     font-size: get-vw(27px, 1800);
        //     line-height: get-vw(37px, 1800);
        // }
    }
    &--35{
        font-size: rem-calc(20px);
        line-height: rem-calc(25px);
        letter-spacing: -.7px;
        .text-size--small{
            font-size: rem-calc(12px);
        }

        @include breakpoint(large){
            font-size: get-vw(20px, 1024);
            line-height: get-vw(25px, 1024);
            .text-size--small{
                font-size: get-vw(12px, 1024);
            }
        }

        // font-size: rem-calc(35px);
        // letter-spacing: -.7px;
        // line-height: rem-calc(44px);

        // @include breakpoint(xxlarge){
        //     font-size: get-vw(35px, 1800);
        //     line-height: get-vw(44px, 1800);
        // }
    }
    &--40,
    &--header{
        font-size: rem-calc(25px);
        line-height: rem-calc(30px);
        letter-spacing: -.8px;

        @include breakpoint(large){
            font-size: get-vw(25px, 1024);
            line-height: get-vw(30px, 1024);
        }

        // font-size: rem-calc(40px);
        // letter-spacing: -.8px;
        // line-height: rem-calc(50px);

        // @include breakpoint(xxlarge){
        //     font-size: get-vw(40px, 1800);
        //     line-height: get-vw(50px, 1800);
        // }
    }
    &--60{
        font-size: rem-calc(30px);
        line-height: rem-calc(35px);
        letter-spacing: -1.2px;

        @include breakpoint(large){
            font-size: get-vw(30px, 1024);
            line-height: get-vw(35px, 1024);
        }

        // font-size: rem-calc(60px);
        // line-height: rem-calc(65px);
        // letter-spacing: -1.2px;

        // @include breakpoint(xxlarge){
        //     font-size: get-vw(60px, 1800);
        //     line-height: get-vw(65px, 1800);
        // }
    }
    &--80{
        
        font-size: rem-calc(40px);
        line-height: rem-calc(45px);
        letter-spacing: -1.6px;

        @include breakpoint(large){
            font-size: get-vw(40px, 1024);
            line-height: get-vw(45px, 1024);
            letter-spacing: -1.6px;
        }

        // @include breakpoint(xxlarge){
        //     font-size: get-vw(80px, 1800);
        //     line-height: get-vw(85px, 1800);
        // }

        // @include breakpoint(large){
        //     font-size: get-vw(60px); //7.8125v, 1800pxw;
        //     line-height: get-vw(65px, 1800);
        //     // margin-bottom: get-vw(90p, 1800pxx)
        // }
        // @include breakpoint(xxlarge){
        //     font-size: get-vw(80px, 1800); //7.8125v, 1800pxw;
        //     line-height: get-vw(85px, 1800);
        //     // margin-bottom: get-vw(90p, 1800pxx)
        // }
        
        // @include breakpoint(medium){
        //     font-size: rem-calc(map-deep-get($header-styles, medium, h1, font-size));
        // }
        
        // @include breakpoint(large){
        //     font-size: rem-calc(map-deep-get($header-styles, large, h1, font-size));
        // }
    }

}


span{
    &.h1,
    &.h2,
    &.h3,
    &.h4,
    &.h5,
    &.h6{
        display: block;
    }
}

// h1:only-child{
//     margin-bottom: 0 !important;
// }

.icon-container{
    display: inline-flex;
}

// p a{
//     position:relative;
//     // &:after{
//     //     content:' ';
//     //     position:absolute;
//     //     top:50%; 
//     //     left:-3%;
//     //     width:0; 
//     //     height:3px;
//     //     //opacity:80%;
//     //     transform:translateY(-50%);
//     //     //background:repeat-x url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB0AAAAKAQMAAAByjsdvAAAABlBMVEUAAADdMzNrjRuKAAAAAXRSTlMAQObYZgAAADdJREFUCNdj+MMABP8ZGCQY/h9g+MHw/AHzDwbGD+w/GBhq6h8wMNj/b2BgkP8HVMMPUsn+gQEAsTkQNRVnI4cAAAAASUVORK5CYII=);
//     //     background: repeat-x url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='3' height='3'%3E%3Cpath data-name='Rectangle 1626' fill='%238a8a8a' d='M0 0h3v3H0z'/%3E%3C/svg%3E");
//     // }
//     &:hover{
//         color:$dark-gray;
//         // &:after {
//         //     animation: strike 200ms linear .3s 1 forwards;
//         // }
//     }
// }

.text-color{
    &--gray{
        color: $dark-gray;
    }
    &--white{
        color: $white;
    }
}

// h1, .h1,
// h2, .h2,
// h3, .h3,
// h4, .h4,
// h5, .h5,
// h6, .h6 {
//     font-family: $header-font-family;
//     font-style: $header-font-style;
//     font-weight: $header-font-weight;
//     color: $header-color;
//     text-rendering: $header-text-rendering;

//     font-size: rem-calc(24px);
//     line-height: $header-lineheight;
//     margin-top: 0;
//     margin-bottom: $header-margin-bottom;

//     @include breakpoint(large){
//         font-size: rem-calc(34px);
//     }
// }




.text-style--highlight //https://codepen.io/cecilehabran/pen/EvRamr
{ 
    flex: 1;
    text-decoration: none;
    background-image: linear-gradient(to right, $green-color 0, $green-color 100%);
    background-position: 0 -0.1em;
    background-size: 0 100%;
    background-repeat: no-repeat;
    transition: background 2s;
    transition-delay: 1s;
    //&:hover {
    &.active{
        background-size: 100% 100%;
    }

    &.wait-1{
        transition-delay: 3s;
    }
    &.wait-2{
        transition-delay: 5s;
    }
    &.wait-3{
        transition-delay: 7s;
    }
    &.wait-4{
        transition-delay: 9s;
    }
}