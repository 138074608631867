.cell-blocks.with-background {
    padding-left: calc(3.75rem - 30px);
    padding-right: calc(3.75rem - 30px);
}

.cell-blocks.with-background .cell {
    margin-bottom: 0;
}

.cell-blocks.cell.with-background {
    padding-bottom: 3.75rem;
}