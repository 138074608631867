.menu-active{
    header{
        z-index: 99;
        svg{
            opacity: 0;
            @include breakpoint(large){
                opacity: 1;
            }
        }
    }

    .menu-toggle::before{
        opacity: 1;
        // display: inline-block;
    }
    
    // The cross
    .menu-toggle span{
        transform: rotate(45deg);
        @include breakpoint(large){
            transform: rotate(0deg);
        }
    }


    @include breakpoint(large){
    &.wf-active #main-nav li{
            opacity: 0;
        }
    }

    @include breakpoint(medium down){
        #main-nav {
            ul{
                display: block;
            }
        }
        .menu-toggle::before{
            opacity: 0;
        }
    }
}

#menu{
    // display: none;
    // opacity: 0;
    // margin-top: $ef-global-padding;
    //transition-delay: 2s;
    flex-grow: 1;
    display: flex;
    justify-content: flex-end;
}

// .menu-active #menu{
//     display: block;
//     opacity: 1;
// }

#main-nav ul li a, 
.menu-toggle
{
    font-family: 'Lexend Giga', sans-serif;
    font-style: normal;
    font-weight: 900;

    // font-size: get-vw(30px, 1024);
    // @include breakpoint(large){
    //     font-size: 30px;
    // }
    // @include breakpoint(xxlarge){
    //     font-size: rem-calc(map-deep-get($header-styles, small, h1, font-size));
    // }

    @include clamp('font-size', 30px, 30px, 30px);



    text-transform: lowercase;
    letter-spacing: rem-calc(-2px);
    position: relative;

    span:nth-child(2){
        opacity: 0;
        position: absolute;
        left: 0;
        top: 0;
    }

    &:hover{
        span:not(.close):nth-child(1){
            opacity: 0;
        }
        span:not(.close):nth-child(2){
            opacity: 1;
        }
    }
}


#main-nav{
    li:nth-last-child(2) {
        z-index: 2;
    } 
    li:last-child(){
        z-index: 1;
    }
    &.is-active{
        li:nth-last-child(2) {
            z-index: initial;
        } 
        li:last-child(){
            z-index: initial;
        }
    }
}

#main-nav{
    
    @include breakpoint(large){
        text-align: right;
        margin-right: 30px;
    }

    //&, 
    ul li{
        margin-right: 30px;

        @include breakpoint(xlarge){
            margin-right: 60px;
            &:last-child{
                margin-right: 24px;
            }
        }
    }
    
    ul{
        margin: 0;
    }
    ul li 
    {
		list-style: none;
        opacity: 0;
        @include breakpoint(large){
            display: inline-flex;
        }
    }

    @include breakpoint(medium down){
        ul{
            display: none;
            position: absolute;
            left: 0;
            padding: 30px;
            

            li a{
                //font-size: get-vw(45px, 360);
                @include clamp('font-size', 45px, 120px, 60px);
            }

            @include breakpoint(medium){
                padding: 60px;
            }
        }   
    }
}



// place 'menu'-text in front of insta-gram if menu is closed and vice-versa 
@include breakpoint(large){
    #main-nav{
        z-index: 1;
        &.is-active{
            z-index: 3;
        }
    }

    .menu-toggle{
        z-index: 2;
    }
}



.menu-toggle{
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    span{
        transition: $ef-global-transition;
        display: inline-block;
        &::after{
            /* The cross */
            content: '';
            display: block;
            width: get-vw(20px, 500);
            height: get-vw(20px, 500);
            min-width: 20px;
            min-height: 20px;
            max-width: 25px;
            max-height: 25px;
            @include breakpoint(large){
                width: 25px;
                height: 25px; 
            }
        }
    }

    
    // &:hover span{
    //     transform: rotate(75deg);
    // }

    @include breakpoint(large){

        span{
            transform: rotate(45deg);
        }
        // &:hover span{
        //     transform: rotate(25deg);
        // }
    }

    &::before{
        transition: $ef-global-transition;
        // transition-delay: 250ms, 250ms;
        // transition-property: margin-right, opacity;
        content: 'menu';
        // display: inline-block;
        position: absolute;
        right: 35px;
        opacity: 1;
        font-size: rem-calc(18px);
        //margin-right: 35px;
        @include breakpoint(large){
            opacity: 0;
            // display: none;
            right: 65px;
            margin-right: 0;
            font-size: inherit;
        }
    }
}

// Menu closing button (X)
.menu-toggle span::after,
.scrolled-first-section .text-white .menu-toggle span::after{
    // background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.908 23.908'%3E%3Cpath data-name='Path 9456' d='M18.232 23.908 0 5.676 5.676 0l18.232 18.189Zm-12.516-.172L.172 18.103 18.016.258l5.59 5.547Z'/%3E%3C/svg%3E");
    // background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24.001' viewBox='0 0 24 24.001'%3E%3Cpath id='Path_9457' data-name='Path 9457' d='M5.7,0,0,5.7l6.324,6.325L.173,18.174l5.566,5.655,6.189-6.2L18.3,24,24,18.26l-6.368-6.354L23.7,5.828,18.086.258,12.029,6.316Z' transform='translate(0 0)' fill='%23040504'/%3E%3C/svg%3E%0A");
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='25' height='25'%3E%3Cpath data-name='Path 9459' d='M8.609 0v8.594H.001l.03 7.812h8.578V25l7.812-.029v-8.565h8.58V8.594h-8.58V0Z' fill='%23040504'/%3E%3C/svg%3E");
    background-size: contain;
}

.text-white .menu-toggle span::after{
    //background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.908 23.908'%3E%3Cpath data-name='Path 9456' d='M18.232 23.908 0 5.676 5.676 0l18.232 18.189Zm-12.516-.172L.172 18.103 18.016.258l5.59 5.547Z' fill='%23fff'/%3E%3C/svg%3E");
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='25' height='25'%3E%3Cpath data-name='Path 9459' d='M8.609 0v8.594H.001l.03 7.812h8.578V25l7.812-.029v-8.565h8.58V8.594h-8.58V0Z' fill='%23fff'/%3E%3C/svg%3E");
}

#main-nav.is-active .menu-toggle{
    &::before{
        opacity: 100%;
    }

    span{
         transform: rotate(135deg);
    }
}

.wf-active #main-nav li{
    opacity: 100%;
}


#main-nav{
    li{
        transition: $ef-global-transition;
        // transition-delay: 250ms, 250ms;
        // transition-property: left, opacity;
        opacity: 0%;
        left: 0;
        position: relative;

    }
    &.is-active{
        li{
            // opacity: 0;
            // visibility: hidden;
        }

        // li:nth-child(4){
        //     left: 50px;
        // }
        // li:nth-child(3){
        //     left: 100px;
        // }
        // li:nth-child(2){
        //     left: 150px;
        // }
        // li:nth-child(1){
        //     left: 200px;
        // }
    }
}