/*
* Design Fordi 2022
*/

:root{
	--primary-color:#{$primary-color};
	--secondary-color:#{$secondary-color};
	// --tertiary-color:#{$tertiary-color};
}

html {
    overflow-y: scroll;
}

body{
    overflow-x: hidden; // foundation - to hide X when .grid-container.full (https://get.foundation/sites/docs/xy-grid.html)
    // background-color: $black;
    // transition: background-color .25s ease-in-out;
     
    // &> main, footer{ //header
    //     max-width: rem-calc(1800px);
    //     margin: 0 auto;
    // }

    // &> main, footer{ //header
    //     padding: 0 2%; //60px;
    // }
}

html.light-bg body{
    background-color: $white;
}

.wf-active header{
    z-index: 50;
    position: fixed;
    top: 0;
    width: 100%;
    display: flex;

    
    padding: $ef-global-padding/2 15px;
    padding-bottom: 0;
    @include breakpoint(xlarge){
        padding: $ef-global-padding;
        padding-bottom: 0;
    }

    svg{
        transition: all .25s ease-in-out;
        position: relative;
        opacity: 1;
        min-width: 200px;
        width: get-vw(350px, 1200);
        height: 100%;
        @include breakpoint(xlarge){
            width: 350px;
        }        
    }

    & > a{
        display: flex;
    //     // display: inline-block;
    //     //width: get-vw(390px, 1024);
    //     // @include breakpoint(large){
    //     //     width: 390px;
    //     // }
    //     display: flex;
    }
}



header.text-white{
    &,
    a{
        color: $white;
        fill: $white;
    }
}

@include breakpoint(medium down){
    .menu-active header.text-white #main-nav a{
        color: $black;
        fill: $black;
    }
}

// //lazy load images
// img[src=""],
// img:not([src]) {
//   visibility: hidden;
//   opacity: 0;
// }

// img:not([src=""]) {
//   opacity: 1;
//   transition: opacity 0.5s;
// }

//lazy load images
picture, video{
    transition: opacity 1s;
    transition-delay: 1s;
    &:not([data-loaded]) {
        visibility: hidden;
        opacity: 0;
    }
    
    &[data-loaded]{
        opacity: 1;
    }
}


//.scrolled header.text-white{
.scrolled-first-section header.text-white{
    &,
    a{
        color: inherit;
        fill: inherit;
    }
}

main + footer
{
    
    padding: $ef-global-padding/2;
    @include breakpoint(large){
        padding: 0 $ef-global-padding $ef-global-padding $ef-global-padding;
    }

    &.primary-background a{
        color: $white;  
    }

    p, a {
        font-size: rem-calc(18px);
        @include breakpoint(large){
            font-size: rem-calc(40px);
        }

    }
    
    .contact-info{
        margin-top: 30px;
        @include breakpoint(medium){
            margin-top: 60px;
        }
    }

    ul.links{
        margin: 0;
        @include breakpoint(small only){
            margin-top: 1rem;
        }
        li{
            list-style: none;
            clear: both;
            
            @include breakpoint(medium){
                float: right;
            }

            a{
                font-family: 'Lexend Giga', sans-serif ;
                text-transform:lowercase; 
                display: inline-block;
                border-bottom: 6px solid $white;
                position: relative;

                &:hover{
                    color: $green-color;
                    border-bottom-color: $green-color;
                }

                span:nth-child(2){
                    opacity: 0;
                    position: absolute;
                    left: 0;
                    bottom: 0;
                }
    
                &:hover{
                    span:not(.close):nth-child(1){
                        opacity: 0;
                    }
                    span:not(.close):nth-child(2){
                        opacity: 1;
                    }
                }
            }
        
            &.small-link{
                a{
                    font-size: rem-calc(14px);
                    font-family: inherit;
                    border-bottom: none;
                }
                margin-top: 60px;
                &:last-child{
                    margin-top:0;
                }
                // &:not(.small-link) + li.small-link{
                // margin-top: rem-calc(20px);
            }
        }
    }

    .logo-collection{
        display:  none;
        @include breakpoint(medium){
            display: block;
            border-bottom: 3px solid #fff;
            padding-bottom: 80px;
            margin-bottom: 80px;
        }
    }
}

// main{
//     z-index: 10;
//     padding-top: calc(#{$ef-global-padding} + 25px);
//     @include breakpoint(large){
//         padding-top: calc(#{$ef-global-padding} + 50px);
//     }

// }
// main{
//     margin-left: auto;
//     margin-right: auto;
//     max-width: 1800px;
// }
// html.light-bg header a svg {
//     fill: #DA4567;
// }


// Font loading
.wf-active{
    //#main-nav ul li a{
    #menu ul li a{
        opacity: 1;
    }

}

// Footer
// body > footer{
//     position: fixed;
//     bottom: 0;
//     .some-links{
//         color: $white;
//         a{
//             padding-left: $ef-global-padding;
//             &:first-child{
//                 padding: 0;
//             }
//         }
//     }
// }


// Heights
// ----------------------

.vh100{ min-height: calc(var(--vh, 1vh) * 100); }
.vh90 { min-height:calc(var(--vh, 1vh)  * 90);  }
.vh80 { min-height:calc(var(--vh, 1vh)  * 80);  }
.vh70 { min-height:calc(var(--vh, 1vh)  * 70);  }
.vh60 { min-height:calc(var(--vh, 1vh)  * 60);  }
.vh50 { min-height:calc(var(--vh, 1vh)  * 50);  }
.vh40 { min-height:calc(var(--vh, 1vh)  * 40);  }
.vh30 { min-height:calc(var(--vh, 1vh)  * 30);  }
.vh20 { min-height:calc(var(--vh, 1vh)  * 20);  }
.vh10 { min-height:calc(var(--vh, 1vh)  * 10);  }

// Subtract header line for first element
// html:not(.home) #section-1{
// #section-1{
//     .vh100{ min-height: calc(var(--vh, 1vh) * 100 - var(--header-height)); }
//     .vh90 { min-height: calc(var(--vh, 1vh) * 90  - var(--header-height)); }
//     .vh80 { min-height: calc(var(--vh, 1vh) * 80  - var(--header-height)); }
//     .vh70 { min-height: calc(var(--vh, 1vh) * 70  - var(--header-height)); }
//     .vh60 { min-height: calc(var(--vh, 1vh) * 60  - var(--header-height)); }
//     .vh50 { min-height: calc(var(--vh, 1vh) * 50  - var(--header-height)); }
//     .vh40 { min-height: calc(var(--vh, 1vh) * 40  - var(--header-height)); }
//     .vh30 { min-height: calc(var(--vh, 1vh) * 30  - var(--header-height)); }
//     .vh20 { min-height: calc(var(--vh, 1vh) * 20  - var(--header-height)); }
//     .vh10 { min-height: calc(var(--vh, 1vh) * 10  - var(--header-height)); }
// }


#curtain{
    position: fixed;
    width: 100%;
    height: 100%;
    right: 0;
    top: 0;
    bottom: 0;
    background-color:$white;//rgba(#000, 0.75);
    z-index: 60;
    transition: $ef-global-transition;

    left: -100%;
    
}

#curtain.green{
    background-color:$green-color;
}
#curtain.black{
    background-color:$black;
}

@include breakpoint(medium down){
    .menu-active {
        overflow-y: hidden;
    }

    .menu-active #curtain {
        left: 0;
    }
}



#curtain.show {
    left: 0;

    // &:after{
    //     content: '';
    //     position: absolute;
    //     width: 100%;
    //     height: 100%;
    //     left: 0;
    //     right: 0;
    //     top: 0;
    //     bottom: 0;
    //     background-color:rgba(#000, 0.75);
    // }
}


#loading{
    position: fixed;
    width: 100%;
    height: 100%;
    right: 0;
    top: 0;
    bottom: 0;
    background-color:$white;//rgba(#000, 0.75);
    z-index: 60;
    transition: $ef-global-transition;

    left: 0;
    
}

#white-spinner, #loader-spinner{
    z-index: 1000;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(50%, 50%);

    opacity: 0;
    transition: all .25s ease-in-out;

    &.active{
        opacity: 1;
    }
}

// Loading effect
// html:not(.loaded){
//     #curtain{
//         left: 0;
//     }
//     #white-spinner{
//         opacity: 1;
//     }
// }




// .menu-active{
//     body {
//         height: 100vh;
//         overflow-y: hidden;
//         padding-right: 15px; /* Avoid width reflow */
//     }
// }

// .scrolled #curtain{
//     // opacity: 0;
//     left: -100%;
    
// }

// .open-menu{
//     &:after{
//         content: '';
//         position: absolute;
//         width: 100%;
//         height: 100%;
//         left: 0;
//         right: 0;
//         top: 0;
//         bottom: 0;
//         background-color:rgba(#000, 0.75);
//     }
// }


#fixed-top{
    position: fixed; 
    width:100%;
    z-index: 0;
    top: 0;
}


// video{
// 	width: 250px;
// }

// p{
//     font-size: rem-calc(16px);
//     @include breakpoint(large){
//         font-size: rem-calc(18px);//rem-calc(20px);
//     }
// }

// .gap-0\.5 {
//     gap: 0.125rem;
// }

.show {
    opacity: 1;
}
.hide {
    opacity: 0;
    transition: opacity 400ms;
}

picture, video{
    display: inline-block;
    width: 100%;
    height: 100%;
    // max-height: 500px;
    // aspect-ratio: 3 / 4;
    object-fit: cover;
    // overflow: hidden;
}


// ******************
// ****** Logo ******
// ******************

// Fade logo
// .scrolled:not(.menu-active){
//     header svg{
//         opacity: 0;
//     }

//     // #logo-payoff{
//     //     opacity: 0;
//     // }
// }

// #logo-payoff,
// #logo-type{
//     transition: $ef-global-transition;
// }

//html.light-bg header a svg g:nth-child(3){
// html.light-bg #logo-type{
//     transition: $ef-global-transition;
//     fill: #000;
// }

// html.menu-active #logo-type{
//     fill: $white;
// }

// html.is-reveal-open #logo-type{
//     fill: $white;
// }


// *****************************
// ****** Layout thingies ******
// *****************************

// section > .grid-container.full {
//     padding-left: $ef-global-padding;
// }


// figure{
//     padding: 0 $ef-global-padding; //60px;
// }

@include breakpoint(xlarge){
    section{
        padding: 0 60px; //$ef-global-padding;
        &> .grid-container.fluid{
            padding-left: 0;
            padding-right: 0;
        }
    }
}

.cell{
	//margin-bottom:60px;
    margin-bottom: calc(#{rem-calc(map-get($grid-margin-gutters , small))} - #{$paragraph-margin-bottom});

    @include breakpoint(medium){
        margin-bottom: calc(#{rem-calc(map-get($grid-margin-gutters , medium))} - #{$paragraph-margin-bottom});
    }

    @include breakpoint(large){
        margin-bottom: calc(#{rem-calc(map-get($grid-margin-gutters , large))} - #{$paragraph-margin-bottom});
    }

    // image cells does not have a paragraph with bottom-margin
    &.image-cell{
        margin-bottom: rem-calc(map-get($grid-margin-gutters , small));
    
        @include breakpoint(medium){
            margin-bottom: rem-calc(map-get($grid-margin-gutters , medium));
        }
    
        @include breakpoint(large){
            margin-bottom: rem-calc(map-get($grid-margin-gutters , large));
        }
    }
}
.cell > .grid-x.grid-margin-x {
    margin: 0;
    padding: 0;
}

main > [class*="-background"],
section,
.grid-container, 
.cell{
	position: relative;
}


.grid-container .grid-margin-x
{
	padding-top: rem-calc(45px);//calc(var(--header-height) + 45px);
	padding-bottom: 0;

	@include breakpoint(medium){
		padding-top: rem-calc(120px); //calc(var(--header-height) + rem-calc(120px));
		//padding-bottom: calc(60px - #{$paragraph-margin-bottom});
	}

}

#section-1 .grid-container .grid-margin-x{
    padding-top:calc(var(--header-height) + 120px);
}

span.icon{
    display: inline-block;
    width: 25px;
    height: 25px;
    background-size:contain;
    background-repeat: no-repeat;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='35' height='50'%3E%3Cdefs%3E%3CclipPath id='a'%3E%3Cpath data-name='Rectangle 1642' d='M0 0h35v50H0z'/%3E%3C/clipPath%3E%3C/defs%3E%3Cg data-name='Group 7284' clip-path='url(%23a)'%3E%3Cpath data-name='Path 8509' d='M17.5 0C7.836 0 0 8.392 0 18.749S17.5 50 17.5 50 35 29.1 35 18.749 27.164 0 17.5 0m.5 27a9 9 0 1 1 9-9 9 9 0 0 1-9 9' fill-rule='evenodd'/%3E%3C/g%3E%3C/svg%3E");
}


/* ONLY EMPLOYEES? */
// .employee{
//     .parent {
//         display: block;
//         width: 100%;
//         height: 450px;
//         max-width:350px;
//         // @include breakpoint(medium){
//         //     height: 500px;
//         // }
//         @include breakpoint(large){
//             max-width: inherit;
//             height: 450px;
//         }
//         overflow: hidden;

//         &:hover .child,
//         &:focus .child {
//             transform: scale(0.97); //1.05
//         }
//     }

//     .child {
//         display: block;
//         width: 100%;
//         height: 100%;
//         background-color: black;
//         background-position: center;
//         background-size: cover;
//         transition: all .8s;
//     }


//     p{
//         position: relative;
//         margin-top: rem-calc(map-get($grid-margin-gutters , small));

//         @include breakpoint(medium){
//             margin-top: rem-calc(map-get($grid-margin-gutters , medium));
//         }
    
//         @include breakpoint(large){
//             margin-top: rem-calc(map-get($grid-margin-gutters , large)/2);
//         }
//     }
// }

// .text-cell{
//     margin-bottom: calc(#{rem-calc(map-get($grid-margin-gutters , small))} - #{$paragraph-margin-bottom});

//     @include breakpoint(medium){
//         margin-bottom: calc(#{rem-calc(map-get($grid-margin-gutters , medium))} - #{$paragraph-margin-bottom});
//     }

//     @include breakpoint(large){
//         margin-bottom: calc(#{rem-calc(map-get($grid-margin-gutters , large))} - #{$paragraph-margin-bottom});
//     }
// }



// .fade {
//     animation-name: fadeIn;
//     animation-duration: 2s;
// }
// //header, main, .curtain, .toggle-button{
// html{
//     animation-name: fadeIn;
//     animation-duration: 500ms;
// }

// .fade-out {
//     animation-name: fadeOut;
//     animation-duration: 500ms;
// }

// @keyframes fadeIn {
//     from {
//         opacity: 0;
//     }
//     to {
//         opacity: 1;
//     }
// }

// @keyframes fadeOut {
//     from {
//         opacity: 1;
//     }
//     to {
//         opacity: 0;
//     }
// }


.black-curtain
{   
    //opacity: 0;
    z-index: 999;
    transition: all .25s ease-in-out;
    background-color: $white;
    position: fixed !important;
    left: 0;
    right:0;
    bottom: 0;
    height: 0;

    &.active{
        //right: 0;
        top: 0;
        height: 100%;
        //opacity: 1;
    }
}

// .no-js .black-curtain{
//     top: 0;
//     height: 100%;

// }

section.cover .cell.text-cell .paragraph-container{
    :last-child{
        margin-bottom: 30px;
    }
}

#call-us-tel{
    position: absolute;
    bottom: 60px;
    z-index: 999;
    
    left: 30px;
    @include breakpoint(medium){
        left: 60px;
    }
    
    display: none;
    @include breakpoint(medium down){
        .menu-active & {
            display: block;
        }
    }
}
