// 11. Button
// ----------------------

@mixin button-with-arrow($color, $arrowposition: 'right') {
    $color: encodecolor($color);

    // background-image:url(../../assets/img/icons/arrow-secondary-color.svg);

    @if $arrowposition == 'left' {
        //background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='23.315' height='11.95' viewBox='0 0 23.315 11.95'%3E%3Cg transform='translate(1454.675 750.975) rotate(180)'%3E%3Cpath d='M1449.01,766.914l4.914-4.914-4.914-4.914' transform='translate(0 -17)' fill='none' stroke='#{$color}' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5'/%3E%3Cline x1='21.815' transform='translate(1432.11 745)' fill='none' stroke='#{$color}' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5'/%3E%3C/g%3E%3C/svg%3E");
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='21.414' height='15.414'%3E%3Cpath data-name='Line 757' fill='none' stroke='#{$color}' stroke-miterlimit='10' stroke-width='2' d='M21.414 7.707h-20'/%3E%3Cpath d='M8.414.707l-7 7 7 7' fill='none' stroke='%23e44231' stroke-miterlimit='10' stroke-width='2'/%3E%3C/svg%3E");
    } @else {
        //background-image: url("data:image/svg+xml,%3Csvg height='11.95' viewBox='0 0 23.315 11.95' width='23.315' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' stroke='#{$color}' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' transform='translate(-1431.36 -756.025)'%3E%3Cpath d='m1449.01 766.914 4.914-4.914-4.914-4.914'/%3E%3Cpath d='m1453.925 762h-21.815'/%3E%3C/g%3E%3C/svg%3E");
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='21.414' height='15.414'%3E%3Cpath data-name='Line 756' fill='none' stroke='#{$color}' stroke-miterlimit='10' stroke-width='2' d='M0 7.707h20'/%3E%3Cpath d='M13 14.707l7-7-7-7' fill='none' stroke='%23e44231' stroke-miterlimit='10' stroke-width='2'/%3E%3C/svg%3E");
    }
    background-repeat: no-repeat;
}

.grid-x > .button-container{
    //@include xy-gutters($negative: true, $gutter-position: left right);
    margin-left: 30px;
    margin-right: 30px;
}

// //p+.button-container
// .cell .button-container
// {
//     padding-top: 20px;//rem-calc(map-get($grid-margin-gutters , small));

//     // @include breakpoint(medium){
//     //     padding-top: rem-calc(map-get($grid-margin-gutters , medium));
//     // }
// }


.button-container a,
.button-container button
{
    display: inline-block;

    &:last-child {
        margin-right: 0;
    }

    &:only-child{
        margin-bottom: 0;
    }


    &:not(.button) {
        padding: $button-padding;
    }
}


// .button.hollow:not(.secondary):not(.primary):not(.secondary)
// {
//     border: 1px solid $body-font-color;
//     &,
//     &.disabled,
//     &:focus,
//     &:hover{
//         color: $body-font-color;
//     }
// }

// .button.white:not(.hollow)
// {
//     &,
//     &.disabled,
//     &:focus,
//     &:hover{
//         color: $white;
//     }
// }

.button.white.hollow
{
    background-color: transparent;
    &,
    &.disabled{
        color: $white;
        border-color: $white;
    }
    // &:focus,
    // &:hover{
    //     background-color: rgba(255, 255, 255, 15%);
    // }
}

// .button.secondary
// {
//     &,
//     &.disabled,
//     &:focus,
//     &:hover{
//         color: $white;
//     }
// }

// .button.white
// {
//     &,
//     &.disabled,
//     &:focus,
//     &:hover{
//         color: $primary-color;
//     }
// }

.button.icon{

    transition: background-position 200ms ease-out;

    &.icon-arrow-left {
        @include button-with-arrow($primary-color, left);
    }

    &.icon-arrow-right {
        @include button-with-arrow($primary-color);
    }

    &.icon-left{
        padding-left: 50px;
        background-position: 15px center;
        background-size: 20px;
        &:hover{
            background-position: 10px center;
        }
    }

    &.icon-right{
        padding-right: 50px;
        background-position: calc(100% - 15px) center;
        &:hover{
            background-position: calc(100% - 10px) center;
        }
    }

}
// .button,
// .button-container a,
// .button-container button
// {
//     &:not(.mail):not(.phone){
//         font-size: rem-calc(13px);
//         padding-right: 60px;
//         display: inline-block;

//         transition: background-position 200ms ease-out;
//         @include button-with-arrow($dark-gray);

//         background-position: calc(100% - 20px) center;

//         &:hover{
//             background-position: calc(100% - 15px) center;
//         }
//     	// @include breakpoint(medium){
//     	// 	min-width: rem-calc(150px);
//     	// }
//     	// @include breakpoint(large){
//     	// 	min-width: rem-calc(190px);
//     	// }
//     }
// }

// .button,
// .button-container a,
// .button-container button
// {
//     &.mail,
//     &.phone{
//         font-size: rem-calc(13px);
//         padding-left: 60px;
//         display: inline-block;

//         // transition: background-size 200ms ease-out;

//         background-position: 15px center;
//         background-repeat: no-repeat;

//         &:hover{
//             // background-position: 15px center;
//             // padding-left: 60px;
//             // background-size: 13%;
//         }

//     }

//     &.mail{
//         background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24.5' height='16.5' viewBox='0 0 24.5 16.5'%3E%3Cpath d='M33.373,64.75h-23a.75.75,0,0,0-.75.75v15a.75.75,0,0,0,.75.75h23a.75.75,0,0,0,.75-.75v-15A.75.75,0,0,0,33.373,64.75Zm-22.25,1.779,8.2,6.457-8.2,6.374Zm19.43-.279-8.68,6.839-8.68-6.839Zm-10.02,7.692.912.719a.689.689,0,0,0,.856,0l.912-.719,7.468,5.808H13.065Zm3.894-.956,8.2-6.457V79.36Z' transform='translate(-9.623 -64.75)' fill='%23fff'/%3E%3C/svg%3E");
//     }

//     &.phone{
//         background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20.802' height='20.893' viewBox='0 0 20.802 20.893'%3E%3Cpath d='M27.282,45.9a2.911,2.911,0,0,0,2.087-.89c.308-.323.622-.641.935-.96l.482-.489a1.584,1.584,0,0,0,.173-.2,1.356,1.356,0,0,0-.013-1.92c-.266-.287-.544-.563-.821-.838l-.813-.814c-.37-.373-.741-.744-1.092-1.084a1.335,1.335,0,0,0-1-.448,1.358,1.358,0,0,0-.917.364c-.089.075-.17.155-.25.236,0,0-1.2,1.2-1.514,1.51a.447.447,0,0,1-.337.155.475.475,0,0,1-.245-.073l-.1-.052c-.258-.131-.52-.264-.766-.409a14.985,14.985,0,0,1-3.369-2.814,12.22,12.22,0,0,1-2.238-3.191.6.6,0,0,1,.215-.831l.262-.255c.438-.426.891-.869,1.286-1.291a1.306,1.306,0,0,0,.069-1.862c-.065-.077-.134-.15-.219-.239l-2.4-2.4a1.432,1.432,0,0,0-2-.33,1.135,1.135,0,0,0-.276.267l-.47.461c-.358.35-.7.687-1.036,1.045a3.293,3.293,0,0,0-.676,1.035,4.011,4.011,0,0,0-.036,2.311,12.922,12.922,0,0,0,1.13,3.116,20.933,20.933,0,0,0,3.242,4.713,20.556,20.556,0,0,0,8.2,5.665,7.852,7.852,0,0,0,1.932.46Z' transform='translate(-11.299 -25.757)' fill='none' stroke='%23fff' stroke-width='1.5'/%3E%3C/svg%3E");    }
// }

// .button.hollow,
// .button.clear{
//     //background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='5.933' height='9.866' viewBox='0 0 5.933 9.866'%3E%3Cpath d='M14,9l3.519,3.519L14,16.037' transform='translate(-12.586 -7.586)' fill='none' stroke='%23E37222' stroke-linecap='round' stroke-linejoin='round' stroke-miterlimit='10' stroke-width='2'/%3E%3C/svg%3E");
//     @include button-with-arrow($primary-color);
// }

// .button:not(.hollow),
// .button:not(.hollow.clear){
//     //background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='5.933' height='9.866' viewBox='0 0 5.933 9.866'%3E%3Cpath d='M14,9l3.519,3.519L14,16.037' transform='translate(-12.586 -7.586)' fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-miterlimit='10' stroke-width='2'/%3E%3C/svg%3E");
//     @include button-with-arrow($white);
// }

// .button.hollow.secondary:not(.mail):not(.phone){
//     //background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='5.933' height='9.866' viewBox='0 0 5.933 9.866'%3E%3Cpath d='M14,9l3.519,3.519L14,16.037' transform='translate(-12.586 -7.586)' fill='none' stroke='%23333333' stroke-linecap='round' stroke-linejoin='round' stroke-miterlimit='10' stroke-width='2'/%3E%3C/svg%3E");
//     @include button-with-arrow($secondary-color);
// }

// .button.secondary{
//     //background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='5.933' height='9.866' viewBox='0 0 5.933 9.866'%3E%3Cpath d='M14,9l3.519,3.519L14,16.037' transform='translate(-12.586 -7.586)' fill='none' stroke='%23333333' stroke-linecap='round' stroke-linejoin='round' stroke-miterlimit='10' stroke-width='2'/%3E%3C/svg%3E");
//     @include button-with-arrow($black);
// }

// .button.dark-gray{
//     //background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='5.933' height='9.866' viewBox='0 0 5.933 9.866'%3E%3Cpath d='M14,9l3.519,3.519L14,16.037' transform='translate(-12.586 -7.586)' fill='none' stroke='%23333333' stroke-linecap='round' stroke-linejoin='round' stroke-miterlimit='10' stroke-width='2'/%3E%3C/svg%3E");
//     @include button-with-arrow($dark-gray);
// }

.button.clear{
    //color: get-color(tertiary);
    text-decoration: none;
    transition:all 0.2s ease-in-out;

    &:hover{
        //color: get-color(tertiary);
        text-decoration: underline;
        text-underline-offset: 5px;
    }
}

.primary-background a.button.clear {
    color:$white;
}

// section.primary-background{
//     .button{
//         &,
//         &.secondary{
//             &.hollow{
//                 color:$white;
//             }
//         }
//     }
// }

// section:not(.primary-background){
//     .button{
//         &,
//         &.secondary{
//             &,
//             &.hollow{
//                 color:$body-font-color;
//             }
//         }
//     }
// }


.button:not(.clear)
{
    text-decoration: none;
}


// PREV button
a.prev{
    @include button-with-arrow($dark-gray, 'left');
    background-position: 8px center;
    &:hover{
        background-position: 5px center;
    }

}

// Next button
a.next{
    @include button-with-arrow($dark-gray, 'right');
    background-position: calc(100% - 8px) center;
    &:hover{
        background-position: calc(100% - 5px) center;
    }

}


// -- Hollow buttons
// .button.hollow,
// .button.hollow.secondary{
//     color: $black;
//     // background-image:url(../../assets/img/icons/arrow-right-color.svg);
// }


// .white-background{
//     .button.hollow{
//         color: $primary-color;
//         border-color:  $primary-color;
//     }
//     .button.clear{
//         color:  $primary-color;
//     }
// }


// .primary-background{
//     .button.hollow{
//         color: $white;
//         border-color: $white;
//     }
//     .button.clear{
//         color: $white;
//     }
// }


// .secondary-background{
//     .button.hollow{
//         color: $secondary-color;
//         // background-image:url(../../assets/img/icons/arrow-right-color.svg);
//     }
//     .button.clear{
//         color: $secondary-color;
//     }
// }

// --


.button.fill-area {
    width: 100%;
}





// .button.white{
//     color: $dark-gray;
//     background-image:url(../../assets/img/icons/arrow-right-color.svg);
// }

.button{
    background-color: $green-color; //$primary-color;
    color: $black;
    padding: 1rem;
    border-radius: 3px;

    //@include button-with-arrow($white, 'right');
    //background-position: calc(100% - 8px) center;
    &:hover{
        //background-position: calc(100% - 5px) center;
        color: smart-scale($black, 20%);
        background-color: $green-color;
    }
}

// .button.secondary{
//     background-color: $secondary-color;
//     color: $black;
// }

.button.white{
    background-color: $white;
    color: $black;
}

.button.black{
    background-color: $black;
    color: $white;
}
